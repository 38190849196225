import { useRouter } from 'next/navigation';
import authType from '@/client/utils/auth/authType';

const useLogoutFromCognito = (
  isBridgeSite: boolean,
  cognitoConfig?: CognitoConfigFragment,
) => {
  const router = useRouter();
  const isCognito =
    authType.get() === 'cognito' && cognitoConfig?.connections?.length;
  const logoutFromCognito = () => {
    if (isCognito) {
      const cognitoDomain = cognitoConfig.domain;
      const cognitoClientId = cognitoConfig.clientId;
      const logoutPath = isBridgeSite ? '/id/logout' : '/logout';
      const logoutUri = `${global.location.origin}${logoutPath}`;
      const url = `https://${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${logoutUri}`;
      router.push(url, undefined);
      authType.remove();
    }
  };
  return {
    isCognito,
    logoutFromCognito,
  };
};
export default useLogoutFromCognito;
