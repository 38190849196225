'use client';
import { getUpdateUserSettingsMutation } from '../../../../shared/graphql/UpdateUserSettingsMutation.generated';
import Checkbox from '@/external/components/Checkbox';

type Props = { label: string; checked: boolean; site: Site };

const FormCheckboxContent: FC<Props> = (props) => {
  const handleClick = () => {
    const value = props.checked ? '0' : '1';

    getUpdateUserSettingsMutation({
      name: 'gameNamesInEnglish',
      value,
    });

    window.location.reload();
  };

  return (
    <div className="flex h-10 items-center justify-center rounded-moon-s-sm bg-gohan py-0 px-5 text-trunks">
      <Checkbox onClick={handleClick} className="rounded-full" {...props} />
    </div>
  );
};

export default FormCheckboxContent;
