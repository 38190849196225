import { useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/navigation';
import { getUpdateSportsbetColorSchemeMutation } from '../graphql/UpdateSportsbetColorScheme.generated';

const toggleTheme = {
  dark: 'light',
  light: 'dark',
} as const;

type Props = {
  site: Site;
  isBridgeSite: boolean;
  preferenceId: string | undefined;
  currentTheme: ColorScheme;
};

const useColorScheme = ({
  currentTheme,
  isBridgeSite,
  site,
  preferenceId,
}: Props) => {
  const [colorSchemeCurrent, setColorSchemeCurrent] =
    useState<ColorScheme>(currentTheme);

  const router = useRouter();

  const handleClick = useCallback(async () => {
    const nextColor = toggleTheme[currentTheme];

    setColorSchemeCurrent(nextColor);

    Cookies.set('theme', nextColor);

    if (isBridgeSite) {
      await getUpdateSportsbetColorSchemeMutation({
        input: {
          site,
          colorScheme: nextColor.toUpperCase() as Uppercase<ColorScheme>,
          id: preferenceId,
        },
      });
    }

    router.refresh();
  }, [currentTheme, isBridgeSite, preferenceId, router, site]);

  return { colorSchemeCurrent, handleClick };
};

export default useColorScheme;
