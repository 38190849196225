enum CustomMessageTypes {
  GQL_CONNECTION_INIT = 'connection_init',
  GQL_CONNECTION_ACK = 'connection_ack',
  GQL_CONNECTION_ERROR = 'connection_error',
  GQL_CONNECTION_KEEP_ALIVE = 'PING',
  GQL_CONNECTION_TERMINATE = 'connection_terminate',
  GQL_START = 'subscribe',
  GQL_DATA = 'subscription update',
  GQL_ERROR = 'error',
  GQL_COMPLETE = 'complete',
  GQL_STOP = 'unsubscribe',
}

export default CustomMessageTypes;
