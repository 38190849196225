'use client';
import { useState } from 'react';
import { useMyLoyaltyStatusSubscription } from './graphql/MyLoyaltyStatusSubscription.generated';
import classNames from '@/external/classNames';
import CircularProgress from '@/external/components/CircularProgress';
import Img from '@/shared/components/Img';

type Props = {
  initialTierImgUrl: string | undefined;
  initialProgress: number;
  site: Site;
};

const LoyaltyProgress: FC<Props> = ({
  initialTierImgUrl,
  initialProgress,
  site,
}) => {
  const [tierImgUrl, setTierImgUrl] = useState(initialTierImgUrl);
  const [progress, setProgress] = useState(initialProgress);

  useMyLoyaltyStatusSubscription({
    variables: {
      input: {},
    },
    onData: (data) => {
      const myStatus = data.loyaltyPublicMyStatusUpdated?.myStatus;
      const newProgress = myStatus?.nextMilestone?.progress;
      if (newProgress) {
        setProgress(newProgress);
      }
      const newTierImgUrl = myStatus?.currentTier?.logoUrl?.replace(
        'small.png',
        'avatar.png',
      );
      if (newTierImgUrl) {
        setTierImgUrl(newTierImgUrl);
      }
    },
  });

  return (
    <>
      <div className="absolute left-1/2 top-1/2 h-8 w-8 -translate-x-1/2 -translate-y-1/2 sm:hidden">
        <CircularProgress value={progress} size="sm" />
      </div>
      <div className="absolute left-1/2 top-1/2 hidden h-10 w-10 -translate-x-1/2 -translate-y-1/2 sm:block">
        <CircularProgress value={progress} size="md" />
      </div>
      <div
        className={classNames(
          'relative z-10 rounded-full bg-frieza rem:h-[26px] rem:w-[26px] sm:h-8 sm:w-8',
          site === 'livecasino' && 'bg-krillin',
        )}
      >
        {tierImgUrl && (
          <Img
            alt=" "
            src={tierImgUrl}
            className="block h-full w-full"
            height={32}
            width={32}
          />
        )}
      </div>
    </>
  );
};

export default LoyaltyProgress;
