'use client';

import useProtoV3 from './hooks/useProtoV3';
import LiveChatButtonDesktopClient from './LiveChatButtonClientDesktop';
import LiveChatButtonMobileClient from './LiveChatButtonClientMobile';

type Props = {
  title: string;
  subtitle?: string;
  iconElement: JSX.Element;
  isMobile?: boolean;
};

const LiveChatButtonClient: FC<Props> = ({
  iconElement,
  title,
  subtitle,
  isMobile,
}) => {
  const { handleClick } = useProtoV3();

  if (isMobile) {
    return <LiveChatButtonMobileClient onClick={handleClick} text={subtitle} />;
  }

  return (
    <LiveChatButtonDesktopClient
      title={title}
      subtitle={subtitle}
      iconElement={iconElement}
      onClick={handleClick}
    />
  );
};

export default LiveChatButtonClient;
