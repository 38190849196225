import { useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import getTopDomain from '../helpers/getTopDomain';
import { getLogoutUserMutation } from '@/client/components/LogoutButton/graphql/LogoutUserMutation.generated';
import removeCustomSymplifyValue from '@/client/helpers/symplify/removeCustomSymplifyValue';
import trackEvent from '@/client/helpers/trackEvent';
import useLogoutFromCognito from '@/client/hooks/useLogoutFromCognito';
import nativeReplace from '@/client/utils/router/nativeReplace';

const useLogout = (
  siteConfig: SiteConfig,
  site: Site,
  cognitoConfig?: CognitoConfigFragment,
) => {
  const [showToast, setShowToast] = useState(false);
  const { pathPrefix = '', isBridgeSite } = siteConfig;
  const { isCognito, logoutFromCognito } = useLogoutFromCognito(
    isBridgeSite,
    cognitoConfig,
  );
  const homeUrl = pathPrefix || '/';

  const logout = useCallback(async () => {
    await getLogoutUserMutation({ site });
    setShowToast(true);
    Cookies.remove('token');
    Cookies.remove('token', { domain: getTopDomain() });
    Cookies.remove('auth0.ssodata');
    trackEvent({ loginType: 'direct' }, 'logoutSuccess');
    removeCustomSymplifyValue();
    window.proto?.reset();
    if (window.gib) {
      window.gib.setAuthStatus(window.gib.IS_NOT_AUTHORIZED);
      window.gib.setSessionID('');
      window.gib.setIdentity('');
    }
    if (isCognito) {
      logoutFromCognito();
      return;
    }
    nativeReplace(homeUrl);
  }, [logoutFromCognito, isCognito, site, homeUrl]);
  return { logout, showToast, setShowToast };
};

export default useLogout;
