'use client';
import type { ComponentProps, HTMLProps } from 'react';
import classNames from '@/external/classNames';
import MoonButton from '@/external/components/Button';

type Props = Omit<HTMLProps<HTMLButtonElement>, 'size'> &
  ComponentProps<typeof MoonButton> & {
    className?: string;
    as?: React.ElementType;
    site: string;
  };

const Button: FC<Props> = ({
  children,
  className,
  variant = 'primary',
  site,
  ...props
}) => {
  const sites = ['bitcasino', 'sportsbet', 'sportsbetmx'];
  return (
    <MoonButton
      data-testid="tw-button"
      className={classNames(
        sites.includes(site) && variant === 'tertiary' && 'text-bulma',
        className,
      )}
      variant={variant}
      {...props}
    >
      {children}
    </MoonButton>
  );
};

export default Button;
