// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
export type UpdateSportsbetColorSchemeMutationVariables = Types.Exact<{
  input: Types.SportsbetPreferencesUpdateColorSchemeInput;
}>;


export type UpdateSportsbetColorSchemeMutation = { __typename?: 'Mutation', sportsbetPreferencesUpdateColorScheme?: { __typename?: 'SportsbetPreferencesUpdateColorSchemePayload', userPreferences?: { __typename?: 'SportsbetPreferencesUserPreference', id: string, colorScheme: Types.ColorScheme } } };


export const UpdateSportsbetColorSchemeMutationDocument = gql`
    mutation UpdateSportsbetColorSchemeMutation($input: SportsbetPreferencesUpdateColorSchemeInput!) {
  sportsbetPreferencesUpdateColorScheme(input: $input) {
    userPreferences {
      id
      colorScheme
    }
  }
}
    `;

export const getUpdateSportsbetColorSchemeMutation = async (variables: UpdateSportsbetColorSchemeMutationVariables) => {
  return fetchGraphql<UpdateSportsbetColorSchemeMutation, UpdateSportsbetColorSchemeMutationVariables>(UpdateSportsbetColorSchemeMutationDocument, "UpdateSportsbetColorSchemeMutation", { variables });
}