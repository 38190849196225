// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
export type SportsbetUpdateLanguageMutationVariables = Types.Exact<{
  id: Types.Scalars['GraphqlId']['input'];
  language: Types.Scalars['String']['input'];
  site: Types.Scalars['String']['input'];
}>;


export type SportsbetUpdateLanguageMutation = { __typename?: 'Mutation', sportsbetPreferencesUpdateLanguage?: { __typename?: 'SportsbetPreferencesUpdateLanguagePayload', userPreferences?: { __typename?: 'SportsbetPreferencesUserPreference', id: string, language?: string } } };


export const SportsbetUpdateLanguageMutationDocument = gql`
    mutation SportsbetUpdateLanguageMutation($id: GraphqlId!, $language: String!, $site: String!) {
  sportsbetPreferencesUpdateLanguage(
    input: {id: $id, language: $language, site: $site}
  ) {
    userPreferences {
      id
      language
    }
  }
}
    `;

export const getSportsbetUpdateLanguageMutation = async (variables: SportsbetUpdateLanguageMutationVariables) => {
  return fetchGraphql<SportsbetUpdateLanguageMutation, SportsbetUpdateLanguageMutationVariables>(SportsbetUpdateLanguageMutationDocument, "SportsbetUpdateLanguageMutation", { variables });
}