'use client';
import { usePathname } from 'next/navigation';

type Props = {
  isDynamic?: boolean;
};

const PartnershipsMenuContainer: FC<Props> = ({ isDynamic, children }) => {
  const pathname = usePathname();
  if ((pathname === '/' && isDynamic) || (pathname !== '/' && !isDynamic)) {
    return children;
  }
  return null;
};

export default PartnershipsMenuContainer;
