import { useCallback } from 'react';
import Cookies from 'js-cookie';
import { usePathname } from 'next/navigation';
import { getChangeCurrentLanguageMutation } from './graphql/ChangeCurrentLanguageMutation.generated';
import { getSportsbetUpdateLanguageMutation } from './graphql/SportsbetUpdateLanguageMutation.generated';
import trackEvent from '@/client/helpers/trackEvent';
import usePathHistory from '@/client/hooks/router/usePathHistory';
import LANGUAGE_COOKIE from '@/shared/utils/cookie/LANGUAGE_COOKIE';
import inAYear from '@/shared/utils/date/inAYear';
import nativeRedirect from '@/shared/utils/router/nativeRedirect';

type Props = {
  isProtoEnabled?: boolean;
  currentLanguage: string;
  isLoggedIn: boolean;
  defaultLang: string;
  isBridgeSite: boolean;
  site: Site;
  languages: LanguageCode[];
  languageFeatureFlags: Partial<Record<LanguageCode, boolean>>;
};

const useLanguageSwitcher = ({
  currentLanguage,
  isLoggedIn,
  defaultLang,
  isBridgeSite,
  site,
  languages,
  languageFeatureFlags,
}: Props) => {
  const { getPreviousPath } = usePathHistory();
  const pathname = usePathname();
  const prevPathname = pathname?.includes('menu')
    ? getPreviousPath()
    : pathname;
  const enabledLanguages = languages.filter(
    (lang) => languageFeatureFlags[lang] !== false,
  );
  const userPreferenceId = Cookies.get('userPreferenceId');

  const updateLang = async (value: string | Language) => {
    if (isBridgeSite) {
      const id = userPreferenceId;
      await getSportsbetUpdateLanguageMutation({
        id,
        site,
        language: value as string,
      });
    } else {
      await getChangeCurrentLanguageMutation({
        language: value as string,
      });
    }
  };

  const inAYearMemo = useCallback(() => inAYear(), []);

  const handleChange = async (value: string) => {
    const locale =
      enabledLanguages.find((lang) => lang === value) ?? defaultLang;

    trackEvent(
      {
        eventInfo: `previous-${currentLanguage}_new-${locale}`,
        eventCategory: 'language_list_item',
        eventAction: 'footer',
      },
      'elementInteraction',
    );

    if (isLoggedIn) {
      await updateLang(locale);
    }

    Cookies.set(LANGUAGE_COOKIE, locale, { expires: inAYearMemo() });
    nativeRedirect(prevPathname ?? '/', locale, defaultLang);
  };

  return { handleChange, languages: enabledLanguages };
};

export default useLanguageSwitcher;
