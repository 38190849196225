const useHideThemeSwitcher = (
  pathname: string | null,
  site: Site,
  loggedIn?: boolean,
) => {
  const hiddenPaths = ['/categories/bombayclub'];
  const isEmpire = site === 'empire';

  if (isEmpire) {
    hiddenPaths.push('/loyalty');
  }

  const isHiddenPath = hiddenPaths.some((path) => pathname?.startsWith(path));
  const isHidden = isHiddenPath || !loggedIn;
  const isHiddenOnMobile = hiddenPaths.some((path) => pathname?.includes(path));

  return { hiddenPaths, isHidden, isHiddenOnMobile };
};

export default useHideThemeSwitcher;
