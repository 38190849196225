'use client';
import CommsCommands from '../helpers/CommsCommands';

type Props = {
  className?: string;
  isCommsEnabled: boolean;
};

const ChatLink: FC<Props> = ({ children, className, isCommsEnabled }) => {
  const handleClick = () => {
    if (isCommsEnabled) {
      CommsCommands.show({});
    }
  };

  return (
    <li onClick={handleClick} className={className}>
      {children}
    </li>
  );
};

export default ChatLink;
