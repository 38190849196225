'use client';
import { ComponentProps } from 'react';
import useLogout from './hooks/useLogout';
import Button from '../Button';
import Snackbar from '@/external/components/Snackbar';

type Props = {
  site: Site;
  siteConfig: SiteConfig;
  cognitoConfig?: CognitoConfigFragment;
  variant?: ComponentProps<typeof Button>['variant'];
  loggedOutToastText: string;
};

const LogoutButton: FC<Props> = ({
  site,
  children,
  siteConfig,
  cognitoConfig,
  loggedOutToastText,
  variant,
}) => {
  const { logout, showToast, setShowToast } = useLogout(
    siteConfig,
    site,
    cognitoConfig,
  );
  return (
    <>
      <Button
        site={site}
        id="logoutButton"
        className="mt-4 w-full font-normal sm:w-auto mb-8"
        onClick={logout}
        variant={variant}
      >
        {children}
      </Button>
      <Snackbar
        isOpen={showToast}
        onOpenChange={setShowToast}
        position="bottom-left"
        className="w-44 rounded-moon-s-md"
      >
        <Snackbar.Message>{loggedOutToastText}</Snackbar.Message>
      </Snackbar>
    </>
  );
};

export default LogoutButton;
