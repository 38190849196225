import useBottomNavigationStore from '@/client/components/BottomProfile/useBottomNavigationStore';
import ChatChat from '@/external/icons/ChatChat';
import ControlsChevronRight from '@/external/icons/ControlsChevronRight';

type Props = {
  onClick?: () => void;
  text: React.ReactNode;
};

const LiveChatButtonClientMobile: FC<Props> = ({ text, onClick }) => {
  const { setCloseAll } = useBottomNavigationStore();
  const handleClick = () => {
    setCloseAll();
    onClick?.();
  };

  return (
    <div
      onClick={handleClick}
      className="px-1 py-2 flex justify-between items-center active:bg-heles"
      data-testid="livechatbutton-mobile"
    >
      <div className="flex items-center">
        <ChatChat className="w-6 h-6 text-bulma" />
        <span className="ml-3 text-sm text-bulma">{text}</span>
      </div>
      <div className="w-6 h-6 text-bulma">
        <ControlsChevronRight />
      </div>
    </div>
  );
};

export default LiveChatButtonClientMobile;
