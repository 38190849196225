'use client';
import { usePathname } from 'next/navigation';
import useColorScheme from './hooks/useColorScheme';
import useHideThemeSwitcher from './hooks/useHideThemeSwitcher';
import classNames from '@/external/classNames';
import Switch from '@/external/components/Switch';
import OtherMoon from '@/external/icons/OtherMoon';
import OtherSun from '@/external/icons/OtherSun';

type Props = {
  site: Site;
  isBridgeSite: boolean;
  preferenceId: string | undefined;
  currentTheme: ColorScheme;
  isMobile?: boolean;
  loggedIn?: boolean;
};

const ThemeSwitcher: FC<Props> = ({
  site,
  isBridgeSite,
  preferenceId,
  currentTheme,
  isMobile,
  loggedIn,
}) => {
  const pathname = usePathname();
  const { colorSchemeCurrent, handleClick } = useColorScheme({
    site,
    preferenceId,
    currentTheme,
    isBridgeSite,
  });

  const { isHidden, isHiddenOnMobile } = useHideThemeSwitcher(
    pathname,
    site,
    loggedIn,
  );

  if (site === 'livecasino' || isHidden || (isMobile && isHiddenOnMobile)) {
    return null;
  }

  return (
    <div className="inline-flex place-items-center gap-2 text-moon-10-caption font-medium text-bulma">
      <span
        className={classNames(colorSchemeCurrent === 'dark' && 'text-trunks')}
      >
        AM
      </span>
      <Switch
        className="bg-beerus"
        checked={colorSchemeCurrent === 'dark'}
        onIcon={<OtherMoon />}
        offIcon={<OtherSun />}
        onBgColor="bg-gohan"
        onChange={handleClick}
        data-testid="theme-switcher"
      />
      <span
        className={classNames(colorSchemeCurrent !== 'dark' && 'text-trunks')}
      >
        PM
      </span>
    </div>
  );
};

export default ThemeSwitcher;
