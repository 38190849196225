// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
import { UserManagementFragmentDoc } from '../../../../shared/graphql/UpdateUserSettingsMutation.generated';
export type LogoutUserMutationVariables = Types.Exact<{
  site?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type LogoutUserMutation = { __typename?: 'Mutation', userManagementLogout?: { __typename?: 'UserManagementLogoutMutationPayload', userManagement?: { __typename?: 'UserManagement', id: string, user?: { __typename: 'UserManagementUser', id: string, email?: string, username?: string, signupTime?: number, authMethods?: Array<string> }, profile?: { __typename: 'UserManagementProfile', _id?: string, id: string, firstName?: string, lastName?: string, maidenName?: string, email?: string, country?: string, phone?: string, affiliateID?: string, city?: string, zip?: string, house?: string, address?: string, state?: string, dateOfBirth?: number, language?: string, hasOtp?: boolean, hasSMS2FA?: boolean, emailVerified?: boolean, passwordChangeDate?: number, contactsRequestedTime?: number, lineId?: string, conversionCurrency?: string, telegramId?: string, allowUsernameChange?: boolean, settings?: { __typename?: 'UserManagementSettings', siteTheme?: string, wallets?: string, loyaltyLandingDisabled?: string, loyaltyLevelNotfDisabled?: string, gameNamesInEnglish?: string, verifyEmailClosed?: string, rtpLiveOnboardingClosed?: string, preferredInGameCurrency?: string, inGameCurrencyOnboardingClosed?: string, hasVisitedBCIO?: string } }, countryInfo?: { __typename: 'UserManagementCountryInfo', id: string, countryCode?: string, blocked?: boolean } }, errors?: Array<{ __typename?: 'UserManagementError', code?: number, message?: string, params?: Array<{ __typename?: 'UserManagementErrorParam', name?: string, value?: string }> }> } };


export const LogoutUserMutationDocument = gql`
    mutation LogoutUserMutation($site: String) {
  userManagementLogout(input: {}) {
    userManagement {
      ...UserManagementFragment
    }
    errors {
      code
      message
      params {
        name
        value
      }
    }
  }
}
    ${UserManagementFragmentDoc}`;

export const getLogoutUserMutation = async (variables: LogoutUserMutationVariables) => {
  return fetchGraphql<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserMutationDocument, "LogoutUserMutation", { variables });
}