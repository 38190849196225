type ParsedMessage = {
  eventData: { data: Record<string, { clientSubscriptionId: string }> };
};

const getClientSubscriptionId = (parsedMessage: ParsedMessage) => {
  try {
    const data = parsedMessage.eventData.data;
    return data[Object.keys(data)[0]].clientSubscriptionId;
  } catch (error) {
    return undefined;
  }
};

export default getClientSubscriptionId;
