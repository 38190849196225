import CustomSubscriptionClient from './CustomSubscriptionClient';
import getGraphqlEndpoint from '@/shared/getGraphqlEndpoint';
import mapHttpsUriToWss from '@/shared/utils/mapHttpsUriToWss';

const getSubscriptionClient = () => {
  // eslint-disable-next-line immutable/no-mutation
  global.subscriptionClient = global.subscriptionClient ?? createClient();
  return global.subscriptionClient;
};

const createClient = () => {
  const graphqlUri = getGraphqlEndpoint();
  const uri = mapHttpsUriToWss(graphqlUri);
  return new CustomSubscriptionClient(uri, { reconnect: true, lazy: true });
};

export default getSubscriptionClient;
