'use client';
import Script from 'next/script';
import identifyUser from '../utils/identifyUser';

type Props = {
  protoSettings: ProtoSettings;
  profileId?: string;
  isLoggedIn: boolean;
};

const ProtoClientScriptV3: FC<Props> = ({
  protoSettings,
  profileId,
  isLoggedIn,
}) => {
  const protoSettngs = {
    ...protoSettings,
    onInit: async () => {
      identifyUser(isLoggedIn, profileId);
    },
  };

  const handleOnLoad = () => {
    window.proto?.init(protoSettngs);
  };

  return (
    <Script
      src="https://embed.proto.cx/index.umd.js"
      async
      type="text/javascript"
      strategy="lazyOnload"
      onLoad={handleOnLoad}
      key={profileId || ''}
    />
  );
};
export default ProtoClientScriptV3;
