const useProtoV3 = () => {
  const handleClick = async () => {
    await window.proto?.open();
  };

  return {
    handleClick,
  };
};

export default useProtoV3;
