type Props = {
  className: string;
};

const InlineContainer: FC<Props> = ({ children, className }) => (
  <div
    className={`flex flex-wrap items-center justify-start [&_li]:list-none ${className}`}
  >
    {children}
  </div>
);

export default InlineContainer;
