import isSsr from '@/shared/utils/isSsr';

const sendEventToUmami = (event: TrackEventType, name?: string) => {
  if (isSsr() || !window.imamu) {
    return;
  }

  console.log(`umami event: ${name || 'track'}`, { ...event });

  window.imamu(name || 'track', { ...event });
};
export default sendEventToUmami;
