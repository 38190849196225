// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
export type ChangeCurrentLanguageMutationVariables = Types.Exact<{
  language: Types.Scalars['String']['input'];
}>;


export type ChangeCurrentLanguageMutation = { __typename?: 'Mutation', userManagementChangeLanguage?: { __typename?: 'UserManagementChangeLanguagePayload', success?: boolean, userManagement?: { __typename?: 'UserManagement', currentProfile?: { __typename?: 'UserManagementProfile', language?: string } } } };


export const ChangeCurrentLanguageMutationDocument = gql`
    mutation ChangeCurrentLanguageMutation($language: String!) {
  userManagementChangeLanguage(input: {language: $language}) {
    success
    userManagement {
      currentProfile {
        language
      }
    }
  }
}
    `;

export const getChangeCurrentLanguageMutation = async (variables: ChangeCurrentLanguageMutationVariables) => {
  return fetchGraphql<ChangeCurrentLanguageMutation, ChangeCurrentLanguageMutationVariables>(ChangeCurrentLanguageMutationDocument, "ChangeCurrentLanguageMutation", { variables });
}