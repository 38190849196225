'use client';

import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';

const Wrapper: FC = ({ children }) => {
  const sendEvent = () => {
    sendEventToUmami(
      {
        menu: 'loyalty',
      },
      'desktopSideMenuClick',
    );
  };

  return <div onClick={sendEvent}>{children}</div>;
};

export default Wrapper;
