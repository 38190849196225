type Props = {
  title: string;
  subtitle?: string;
  iconElement: JSX.Element;
  onClick?: () => void;
};

const LiveChatButtonClientDesktop: FC<Props> = ({
  title,
  subtitle,
  iconElement,
  onClick,
}) => (
  <div
    className={`group/item my-2 grid grid-flow-col items-center justify-between [&_p&_div]:duration-200 cursor-pointer`}
    onClick={onClick}
  >
    <span
      className="grid grid-flow-col grid-cols-[minmax(2rem,1fr)] items-center gap-3"
      data-gtm-event-category="Menu_item"
      data-gtm-event-action="sidebar_menu"
      data-gtm-event-info={title}
    >
      {iconElement}
      <div className="grid">
        <div className="flex gap-1 items-center">
          <p className="text-moon-16 text-bulma font-[500] group-hover/item:text-piccolo">
            {title}
          </p>
        </div>

        <p className="text-moon-12 text-raditz w-full">{subtitle}</p>
      </div>
    </span>
  </div>
);

export default LiveChatButtonClientDesktop;
