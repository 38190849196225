'use client';
import { useEffect, useState } from 'react';
import getFavouritesRecentsCount from '@/actions/getFavouritesRecentsCount';

type Props = {
  initialText: string | number;
  site: Site;
  isLoggedIn: boolean;
  id?: string;
};

const BadgeText: FC<Props> = ({ initialText, id }) => {
  const [badgeText, setBadgeText] = useState(initialText);
  const updateBadgeText = async () => {
    const { favourites } = await getFavouritesRecentsCount();
    if (favourites?.totalCount || favourites?.totalCount === 0) {
      setBadgeText(favourites.totalCount);
    }
  };
  useEffect(() => {
    if (id === 'favourite-games') {
      window.addEventListener('favouriteToggle', updateBadgeText);
      return () => {
        window.removeEventListener('favouriteToggle', updateBadgeText);
      };
    }
    return () => {};
  });

  return (
    <p className="text-moon-16 text-trunks sm:text-moon-14">{badgeText}</p>
  );
};

export default BadgeText;
