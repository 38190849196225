'use client';
import { useEffect, useState } from 'react';
import Carousel from '@/external/components/Carousel';

const switchingDelay = 4000;

type Props = {
  items: JSX.Element[];
};

const AnimatedCarousel: FC<Props> = ({ items }) => {
  const [delay, setDelay] = useState(switchingDelay);

  useEffect(() => {
    const listener = () => {
      if (document.visibilityState === 'hidden') {
        setDelay(0);
      } else {
        setDelay(switchingDelay);
      }
    };
    document.addEventListener('visibilitychange', listener);
    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, []);

  return (
    <div
      onMouseEnter={() => setDelay(0)}
      onMouseLeave={() => setDelay(switchingDelay)}
    >
      <Carousel autoSlideDelay={delay} step={1}>
        <Carousel.Reel className="overflow-hidden scroll-hidden">
          {items.map(
            (item: React.ReactNode, index: string | number | undefined) => (
              // eslint-disable-next-line react/no-array-index-key
              <Carousel.Item key={index} className="w-full bg-transparent">
                {item}
              </Carousel.Item>
            ),
          )}
        </Carousel.Reel>
      </Carousel>
    </div>
  );
};

export default AnimatedCarousel;
