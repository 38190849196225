import { usePathname } from 'next/navigation';

const usePathHistory = () => {
  const asPath = usePathname();
  const savePreviousPath = () => {
    sessionStorage.setItem('previousPath', asPath ?? '/');
  };

  const getPreviousPath = (): string | null =>
    sessionStorage.getItem('previousPath');

  return {
    savePreviousPath,
    getPreviousPath,
  };
};

export default usePathHistory;
