'use client';
import useLanguageSwitcher from './useLanguageSwitcher';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';
import NativeSelect from '@/external/components/NativeSelect';

const languageCodeToTitleMapper: Record<LanguageCode, string> = {
  ar: 'العربية',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fi: 'Suomi',
  fr: 'Français',
  ja: '日本語',
  ko: '한국어',
  no: 'Norsk',
  pt: 'Português',
  th: 'ไทย',
  tr: 'Türkçe',
  vi: 'Tiếng Việt',
  zh: '中文',
};

type Props = {
  currentLanguage: string;
  isLoggedIn: boolean;
  defaultLang: string;
  isBridgeSite: boolean;
  site: Site;
  languages: LanguageCode[];
  languageFeatureFlags: Partial<Record<LanguageCode, boolean>>;
};

const LanguageSwitcher: FC<Props> = ({
  currentLanguage,
  isLoggedIn,
  defaultLang,
  isBridgeSite,
  site,
  languages,
  languageFeatureFlags,
}) => {
  const { handleChange } = useLanguageSwitcher({
    currentLanguage,
    isLoggedIn,
    defaultLang,
    isBridgeSite,
    site,
    languages,
    languageFeatureFlags,
  });

  const filteredLanguages = languages.filter(
    (lang) => languageFeatureFlags[lang] !== false,
  );

  return (
    <NativeSelect
      className="[&_select]:text-trunks [&_select]:bg-goku"
      data-testid="language-switcher"
      value={currentLanguage}
      onChange={(e) => {
        sendEventToUmami(
          {
            languageCode: e.target.value,
          },
          'changeLanguage',
        );

        handleChange(e.target.value);
      }}
    >
      {languageCodeToTitleMapper[currentLanguage as LanguageCode]}
      {filteredLanguages.map((code) => (
        <option value={code} key={code}>
          {languageCodeToTitleMapper[code]}
        </option>
      ))}
    </NativeSelect>
  );
};

export default LanguageSwitcher;
