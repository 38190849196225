'use client';
import { useState } from 'react';
import { useMyLoyaltyStatusSubscription } from './graphql/MyLoyaltyStatusSubscription.generated';
import Progress from '@/external/components/Progress';
import InlineContainer from '@/shared/components/InlineContainer';

type Props = {
  initialTierPoints: number;
  initialPoints: string | undefined;
  initialProgress: number;
};

const LoyaltySidebarProgress: FC<Props> = ({
  initialTierPoints,
  initialPoints,
  initialProgress,
}) => {
  const [progress, setProgress] = useState(initialProgress);
  const [tierPoints, setTierPoints] = useState(initialTierPoints);
  const [points, setPoints] = useState(initialPoints);

  useMyLoyaltyStatusSubscription({
    variables: {
      input: {},
    },
    onData: (data) => {
      const { myStatus } = data.loyaltyPublicMyStatusUpdated || {};
      if (myStatus?.nextMilestone?.progress) {
        setProgress(myStatus.nextMilestone.progress);
      }
      if (myStatus?.nextMilestone?.points) {
        setPoints(myStatus.nextMilestone.points);
      }
      if (myStatus?.tierPoints) {
        setTierPoints(Math.trunc(myStatus.tierPoints));
      }
    },
  });

  return (
    <div className="grid gap-2 px-1 py-0">
      <Progress value={progress} bgColor="bg-goku sm:bg-gohan" size="5xs" />
      <InlineContainer className="gap-2">
        <p className="text-moon-12 text-bulma">{tierPoints}</p>
        <p className="text-moon-12 text-bulma">/</p>
        <p className="text-moon-12 text-bulma">{points}</p>
      </InlineContainer>
    </div>
  );
};

export default LoyaltySidebarProgress;
