'use client';

import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';

type Props = {
  icon: string;
};

const UserWrap: FC<Props> = ({ children, icon }) => (
  <div
    onClick={() => {
      sendEventToUmami(
        {
          menu: icon,
        },
        'desktopSideMenuClick',
      );
    }}
  >
    {children}
  </div>
);

export default UserWrap;
