import getClientSubscriptionId from './getClientSubscriptionId';

const parseMessage = (receivedData: string) => {
  try {
    const parsedMessage = JSON.parse(receivedData);
    const opId =
      parsedMessage?.eventData?.id ||
      getClientSubscriptionId(parsedMessage) ||
      parsedMessage?.eventData?.data?.loyaltyPublicMyStatusUpdated
        ?.clientSubscriptionId ||
      parsedMessage?.eventData?.data?.loyaltyPublicMyTiersUpdated
        ?.clientSubscriptionId ||
      parsedMessage?.eventData?.data?.loyaltyPublicMyMilestonesUpdated
        ?.clientSubscriptionId ||
      parsedMessage?.eventData?.data?.kycStatus?.clientSubscriptionId;
    return { parsedMessage, opId };
  } catch (error) {
    throw new Error(`Message must be JSON-parseable. Got: ${receivedData}`);
  }
};

export default parseMessage;
