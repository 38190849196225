import { useEffect } from 'react';
import getSubscriptionClient from './getSubscriptionClient';

type Options<T, V> = {
  variables?: V;
  onData: (data: T) => void;
};

const useSubscription = <
  T,
  V extends Record<
    string,
    string | Record<string, string | boolean> | number | boolean
  >,
>(
  query: string,
  { variables, onData }: Options<T, V>,
) => {
  useEffect(() => {
    const subscription = getSubscriptionClient()
      .request({ query, variables })
      .subscribe({
        error: console.error,
        next: (value) => {
          const subscriptionData = value.data;
          if (subscriptionData) {
            onData(subscriptionData as T);
          }
        },
      });
    return () => subscription.unsubscribe();
  }, [onData, query, variables]);
};

export default useSubscription;
