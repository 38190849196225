import CustomMessageTypes from './CustomMessageTypes';
import MessageTypes from './MessageTypes';

const mapMessageType = (message: string) => {
  switch (message) {
    case MessageTypes.GQL_CONNECTION_INIT:
      return CustomMessageTypes.GQL_CONNECTION_INIT;
    case MessageTypes.GQL_CONNECTION_ACK:
      return CustomMessageTypes.GQL_CONNECTION_ACK;
    case MessageTypes.GQL_CONNECTION_ERROR:
      return CustomMessageTypes.GQL_CONNECTION_ERROR;
    case MessageTypes.GQL_CONNECTION_KEEP_ALIVE:
      return CustomMessageTypes.GQL_CONNECTION_KEEP_ALIVE;
    case MessageTypes.GQL_CONNECTION_TERMINATE:
      return CustomMessageTypes.GQL_CONNECTION_TERMINATE;
    case MessageTypes.GQL_START:
      return CustomMessageTypes.GQL_START;
    case MessageTypes.GQL_DATA:
      return CustomMessageTypes.GQL_DATA;
    case MessageTypes.GQL_ERROR:
      return CustomMessageTypes.GQL_ERROR;
    case MessageTypes.GQL_COMPLETE:
      return CustomMessageTypes.GQL_COMPLETE;
    case MessageTypes.GQL_STOP:
      return CustomMessageTypes.GQL_STOP;
    default:
      return undefined;
  }
};

export default mapMessageType;
