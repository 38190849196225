// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../schema';

import useSubscription from '@/client/hooks/useSubscription';
import gql from '@/shared/utils/gql';
export type MyLoyaltyStatusSubscriptionVariables = Types.Exact<{
  input: Types.LoyaltyPublicMyStatusUpdatedInput;
}>;


export type MyLoyaltyStatusSubscription = { __typename?: 'Subscription', loyaltyPublicMyStatusUpdated?: { __typename?: 'LoyaltyPublicMyStatusUpdatedPayload', clientSubscriptionId?: string, myStatus?: { __typename?: 'LoyaltyPublicMyStatus', id?: string, tierPoints?: number, currentTier?: { __typename?: 'LoyaltyPublicUserTier', id: string, logoUrl?: string }, nextMilestone?: { __typename?: 'LoyaltyPublicMilestone', id: string, points?: string, progress?: number } } } };


export const MyLoyaltyStatusSubscriptionDocument = gql`
    subscription MyLoyaltyStatusSubscription($input: LoyaltyPublicMyStatusUpdatedInput!) {
  loyaltyPublicMyStatusUpdated(input: $input) {
    myStatus {
      id
      tierPoints
      currentTier {
        id
        logoUrl
      }
      nextMilestone {
        id
        points
        progress
      }
    }
    clientSubscriptionId
  }
}
    `;

export const useMyLoyaltyStatusSubscription = (options: { variables?: MyLoyaltyStatusSubscriptionVariables, onData: (data: MyLoyaltyStatusSubscription) => void } ) => { 
  return useSubscription<MyLoyaltyStatusSubscription, MyLoyaltyStatusSubscriptionVariables>(MyLoyaltyStatusSubscriptionDocument, options);
}